import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
// import ScrollSnap from 'scroll-snap'
import scrollSnapPolyfill from 'css-scroll-snap-polyfill'

import './layout.sass'

interface LayoutProps {
  children: any
}
// Polyfill for the scrollSnap CSS
function runPoly() {
  useEffect(() => scrollSnapPolyfill(), [])
  return null
}

const Layout: React.SFC<LayoutProps> = ({ children, bgColor }) => {
  runPoly()
  return (
    <main style={{ backgroundColor: bgColor }}>
      <Helmet>
        <title>Royals X Coopers</title>
      </Helmet>
      {children}
    </main>
  )
}

export default Layout
