import React from 'react'
import Anchor from '../../Anchor'

import './style.sass'

interface TextModuleCenteredProps {
  content: {
    id: string
    anchor: string
    centeredTitle: string
    theme: string
    bodyCopy: {
      childMarkdownRemark: {
        html: string
      }
    }
  }
}

const TextModuleCentered: React.SFC<TextModuleCenteredProps> = ({
  content,
}) => {
  return (
    <Anchor history={content.anchor} sectionTheme={content.theme}>
      <section
        className={`textmodulecentered ${content.theme}`}
        id={content.anchor}
      >
        <div className="text">
          <h2>{content.centeredTitle}</h2>
          <div
            className="markdown"
            dangerouslySetInnerHTML={{
              __html: content.bodyCopy.childMarkdownRemark.html,
            }}
          />
        </div>
      </section>
    </Anchor>
  )
}

export default TextModuleCentered
