import React from 'react'
import Anchor from '../Anchor'

import './style.sass'

interface ColourSchemeProps {
  content: {
    anchor: string
    palettes: string
    colours: string[]
    accentColours: string[]
  }
}

function getP3Width(colours, current) {
  // console.log('current: ', current)
  // console.log('colours: ', colours)
  if (current > 1) {
    return 100 / (colours.length - 2) + '%'
  }
  return null
}

function getColours(content) {
  if (Array.isArray(content.colours)) {
    if (content.palettes.toLowerCase() === 'palette1') {
      return (
        <React.Fragment>
          <p className="primary">Primary</p>
          <div className="primary-colours">
            {content.colours.map(colour => (
              <div
                className="colour"
                style={{ background: colour }}
                key={colour}
              />
            ))}
          </div>
          <p className="accent">Accent</p>
          <div className="accent-colours">
            {Array.isArray(content.accentColours) &&
              content.accentColours.map(colour => (
                <div
                  className="colour"
                  style={{ background: colour }}
                  key={colour}
                />
              ))}
          </div>
        </React.Fragment>
      )
    }
    if (content.palettes.toLowerCase() === 'palette2') {
      return (
        <React.Fragment>
          {content.colours.map(colour => (
            <div className="colour" key={colour} style={{ background: colour }}>
              <p className="hex">{colour}</p>
            </div>
          ))}
        </React.Fragment>
      )
    }
    if (content.palettes.toLowerCase() === 'palette3') {
      return (
        <React.Fragment>
          {content.colours.map((colour, index) => {
            const colourWidth = getP3Width(content.colours, index)
            // console.log('colourWidth: ', colourWidth)
            return (
              <div
                className="colour"
                key={colour}
                style={{
                  background: colour,
                  width: colourWidth,
                }}
              >
                <p className="hex">{colour}</p>
              </div>
            )
          })}
        </React.Fragment>
      )
    }
  }

  return null
}

const ColourScheme: React.SFC<ColourSchemeProps> = ({ content }) => {
  // console.log('content: ', content)
  const palette = content.palettes.toLowerCase()
  const getTheme = () => {
    if (palette === 'palette1') {
      return 'theme-light'
    }
    return 'theme-dark'
  }
  return (
    <Anchor history={content.anchor} sectionTheme={getTheme()}>
      <section className={`colourscheme`} id={content.anchor}>
        <div className={`${palette}`}>{getColours(content)}</div>
      </section>
    </Anchor>
  )
}

export default ColourScheme
