import React, { useState } from 'react'
import Img, { FluidObject } from 'gatsby-image'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import { easeQuadIn } from 'd3-ease'
import Anchor from '../Anchor'
import AnimatedProgressProvider from './AnimatedProgressProvider'

import './style.sass'

interface StatisticsProps {
  content: {
    id: string
    anchor: string
    statistics: {
      id: string
      headline: string
      description: {
        id: string
        childMarkdownRemark: {
          html: string
        }
      }
      prefix: string
      suffix: string
      minimum: number
      maximum: number
    }[]
    image: { fluid: FluidObject }
  }
}

const Statistics: React.SFC<StatisticsProps> = ({ content }) => {
  const [playAnimation, setAnimation] = useState(false)
  // console.log('content: ', content)

  // Set up state change here so it can be passed to the Waypoint
  const startAnimation = () => setAnimation(true)
  const stopAnimation = () => setAnimation(false)

  return (
    <Anchor
      history={content.anchor}
      callbackEnter={startAnimation}
      callbackLeave={stopAnimation}
      sectionTheme="theme-light"
    >
      <section className={`statistics`} id={content.anchor}>
        <div className="container">
          {Array.isArray(content.statistics) &&
            content.statistics.map((statistic, index) => (
              <div className="statistic row" key={statistic.id}>
                <div className="col-xs-2">
                  <AnimatedProgressProvider
                    valueStart={statistic.minimum}
                    valueEnd={statistic.endValue}
                    duration={4.4}
                    delay={500 * index}
                    easingFunction={easeQuadIn}
                    playAnimation={playAnimation}
                  >
                    {value => {
                      const roundedValue = Math.round(value)
                      const prefix = statistic.prefix ? statistic.prefix : ''
                      const suffix = statistic.suffix ? statistic.suffix : ''
                      return (
                        <CircularProgressbar
                          value={value}
                          maxValue={statistic.maximum}
                          text={`${prefix}${roundedValue}${suffix}`}
                          strokeWidth="2"
                          /* This is important to include, because if you're fully managing the
                animation yourself, you'll want to disable the CSS animation. */
                          styles={buildStyles({ pathTransition: 'none' })}
                        />
                      )
                    }}
                  </AnimatedProgressProvider>
                </div>
                <div className="col-xs-5">
                  <div className="content">
                    <h3>{statistic.headline}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: statistic.description.childMarkdownRemark.html,
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
        <Img fluid={content.image.fluid} style={{ position: 'absolute' }} />
      </section>
    </Anchor>
  )
}

export default Statistics
