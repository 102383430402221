import React, { useState } from 'react'
import Img, { FluidObject } from 'gatsby-image'
import { Link } from 'gatsby'

import Anchor from '../Anchor'

import './style.sass'

interface Props {
  content: {
    id: string
    anchor: string
    carousel: {
      id: string
      fluid: FluidObject
    }[]
  }
}

const ImageLinks: React.SFC<Props> = ({ content }) => {
  return (
    <Anchor
      history={content.anchor || 'our-clients'}
      sectionTheme="theme-light"
    >
      <section className="image-links" id={content.anchor || 'our-clients'}>
        {Array.isArray(content.linksList) &&
          content.linksList.map(imageObj => {
            return (
              <Link key={imageObj.linkText} to={imageObj.linkRef}>
                <div className="image-link">
                  <Img
                    className="background"
                    style={{ position: 'absolute' }}
                    fluid={imageObj.image.fluid}
                  />
                  {/* <div className="overlay" /> */}
                  <div className="client">{imageObj.linkText}</div>
                </div>
              </Link>
            )
          })}
      </section>
    </Anchor>
  )
}

export default ImageLinks
