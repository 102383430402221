import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import Anchor from '../Anchor'

import './style.sass'

interface HeroProps {
  content: {
    id: string
    anchor?: string
    heroTitle?: string
    html?: string
    subtitle?: string
    foregroundImage: { fluid: FluidObject }
    backgroundImage: { fluid: FluidObject }
  }
}

const SM = 'SM'
const LG = 'LG'
const UNSET = 'UNSET'

const Hero: React.SFC<HeroProps> = ({
  content,
  useAnchor = true,
  homeBtn = false,
}) => {
  if (!content) {
    return null
  }
  const [viewType, setViewType] = useState(UNSET)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth <= 667 && viewType !== SM) {
        return setViewType(SM)
      }
      if (window.innerWidth > 667 && viewType !== LG) {
        return setViewType(LG)
      }
    }
  })

  const displayBg = () => {
    if (
      viewType === SM &&
      content.mobileBackgroundImage &&
      content.mobileBackgroundImage.fluid
    ) {
      return (
        <Img
          className="background"
          style={{ position: 'absolute' }}
          fluid={content.mobileBackgroundImage.fluid}
        />
      )
    }

    if (content.backgroundImage) {
      return (
        <Img
          className="background"
          style={{ position: 'absolute' }}
          fluid={content.backgroundImage.fluid}
        />
      )
    }

    return null
  }

  const displayFg = () => {
    if (
      viewType === SM &&
      content.mobileForegroundImage &&
      content.mobileForegroundImage.fluid
    ) {
      return (
        <Img
          className="foreground"
          style={{
            margin: '0 auto',
            maxWidth: '150px',
          }}
          fluid={content.mobileForegroundImage.fluid}
        />
      )
    }

    if (content.foregroundImage) {
      return (
        <Img className="foreground" fluid={content.foregroundImage.fluid} />
      )
    }

    return null
  }

  const heroInner = (
    <section
      className={`hero ${useAnchor === true ? '' : 'no-snap'}`}
      id={content.anchor}
    >
      {homeBtn && (
        <Link to="/" className="go-home">
          HOME
        </Link>
      )}
      {content.heroTitle && <div>{content.heroTitle}</div>}
      {displayFg()}
      {content.bodyCopy && content.bodyCopy.childMarkdownRemark && (
        <div
          className="html-block"
          dangerouslySetInnerHTML={{
            __html: content.bodyCopy.childMarkdownRemark.html,
          }}
        />
      )}
      {displayBg()}
    </section>
  )

  if (useAnchor === true) {
    return <Anchor history={content.anchor}>{heroInner}</Anchor>
  }

  return heroInner
}

export default Hero
