import React from 'react'
import Anchor from '../Anchor'

import './style.sass'

interface TypefaceProps {
  content: {
    id: string
    anchor: string
    theme: string
  }
}

const Typeface: React.SFC<TypefaceProps> = ({ content }) => {
  // console.log('content: ', content)
  return (
    <Anchor history={content.anchor} sectionTheme={content.theme}>
      <section className={`typeface ${content.theme}`} id={content.anchor}>
        <div className="container">
          <div className="row">
            <div className="col-xs-10 offset-xs-2">
              <div className="headline">
                <h5>Headline</h5>
                <div className="type">
                  <p>
                    Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm
                    <br />
                    Nn Oo Pp Qq Rr Ss Tt Uu Vv Ww Xx Yy Zz
                  </p>
                </div>
              </div>
              <div className="subhead">
                <h5>SubHead</h5>
                <div className="type">
                  <p>
                    Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm
                    <br />
                    Nn Oo Pp Qq Rr Ss Tt Uu Vv Ww Xx Yy Zz
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Anchor>
  )
}

export default Typeface
