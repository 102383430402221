import React from 'react'
import { Animate } from 'react-move'

class AnimatedProgressProvider extends React.Component {
  interval = undefined

  constructor(props) {
    super(props)

    this.state = {
      isAnimated: false,
    }

    this.animate = this.animate.bind(this)
  }

  static defaultProps = {
    valueStart: 0,
  }

  animate() {
    if (this.state.isAnimated !== this.props.playAnimation) {
      this.setState({
        isAnimated: this.props.playAnimation,
      })
    }
  }

  render() {
    this.animate()

    return (
      <Animate
        start={() => ({
          value: this.props.valueStart,
        })}
        update={() => ({
          value: [
            this.state.isAnimated ? this.props.valueEnd : this.props.valueStart,
          ],
          timing: {
            duration: this.props.duration * 1000,
            ease: this.props.easingFunction,
            delay: this.props.delay,
          },
        })}
      >
        {({ value }) => this.props.children(value)}
      </Animate>
    )
  }
}

export default AnimatedProgressProvider
