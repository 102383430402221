import React from 'react'
import Anchor from '../../Anchor'

import './style.sass'

interface TextModuleCtaProps {
  content: {
    id: string
    anchor: string
    ctaTitle: string
    ctaText: string
    ctaLink: string
    theme: string
    substringHighlight: string
    bodyCopy: {
      id: string
      childMarkdownRemark: {
        html: string
      }
    }
  }
}

const addHighlightElem = (str: string, target: string = ''): string => {
  if (!target) {
    return str
  }

  return str.replace(target, `<span class="highlight-this">${target}</span>`)
}

const TextModuleCta: React.SFC<TextModuleCtaProps> = ({ content }) => {
  return (
    <Anchor history={content.anchor} sectionTheme={content.theme}>
      <section className={`textmodulecta ${content.theme}`} id={content.anchor}>
        <div className="container">
          <div className="inner">
            {content.ctaTitle && (
              <h2
                dangerouslySetInnerHTML={{
                  __html:
                    content.theme === 'theme-highlighter'
                      ? addHighlightElem(
                          content.ctaTitle,
                          content.substringHighlight
                        )
                      : content.ctaTitle,
                }}
              />
            )}
            <div
              className="content-body"
              dangerouslySetInnerHTML={{
                __html:
                  content.bodyCopy && content.bodyCopy.childMarkdownRemark.html,
              }}
            />
            {content.ctaLink && content.ctaText && (
              <a className="btn" href={content.ctaLink}>
                {content.ctaText}
              </a>
            )}
            <a
              className="btn"
              href="https://downloads.ctfassets.net/ic6xcdblapnb/14FJev3sQz4WppO9MU2JrV/5425c2721fb9ba544968cd6d53c24e36/RoyalsxCoopers_Final_Social_Presentation_191001.pdf"
            >
              SOCIAL MEDIA RESPONSE
            </a>
          </div>
        </div>
      </section>
    </Anchor>
  )
}

export default TextModuleCta
