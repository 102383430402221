import React, { useState } from 'react'
import Img, { FluidObject } from 'gatsby-image'
import Anchor from '../Anchor'

import { ReactComponent as SunIcon } from '../../assets/vector/icon-sun.svg'
import './style.sass'

interface LogoProps {
  content: {
    id: string
    anchor: string
    theme: string
    logo: { fluid: FluidObject }
    logoLight: { fluid: FluidObject }
  }
}

const Logo: React.SFC<LogoProps> = ({ content }) => {
  // State for storing mouse coordinates
  const [theme, setTheme] = useState(content.theme)

  function changeTheme() {
    const colour = theme === 'theme-light' ? 'theme-dark' : 'theme-light'
    setTheme(colour)
  }

  return (
    <Anchor history={content.anchor} sectionTheme={theme}>
      <section className={`logo ${theme}`} id={content.anchor}>
        <div className="container">
          {theme === 'theme-dark' ? (
            <Img fluid={content.logo.fluid} />
          ) : (
            <Img fluid={content.logoLight.fluid} />
          )}
          {content.logo && content.logoLight ? (
            <SunIcon onClick={changeTheme} />
          ) : null}
        </div>
      </section>
    </Anchor>
  )
}

export default Logo
