import React from 'react'
import Img, { FluidObject } from 'gatsby-image'
import Anchor from '../../Anchor'

import './style.sass'

interface TextModuleLongProps {
  content: {
    id: string
    anchor: string
    theme: string
    title: {
      childMarkdownRemark: {
        html: string
      }
    }
    bodyCopy: {
      childMarkdownRemark: {
        html: string
      }
    }
    featurePoints: Array<{
      id: string
      title: string
      desc: {
        childMarkdownRemark: {
          html: string
        }
      }
    }>
  }
}

const TextModuleLong: React.SFC<TextModuleLongProps> = ({ content }) => {
  const getBodyCopy = () => {
    if (content.bodyCopy) {
      return content.bodyCopy.childMarkdownRemark.html
    }
    return ''
  }

  return (
    <Anchor history={content.anchor} sectionTheme={content.theme}>
      <section
        className={`textmodulelong ${content.theme}`}
        id={content.anchor}
      >
        <div className="container">
          <div
            className="headline"
            dangerouslySetInnerHTML={{
              __html: content.title.childMarkdownRemark.html,
            }}
          />
          <div
            className="subline"
            dangerouslySetInnerHTML={{ __html: getBodyCopy() }}
          />
          <div className="feature-points">
            {Array.isArray(content.featurePoints) &&
              content.featurePoints.map(point => (
                <div
                  className="point"
                  key={point.id}
                  dangerouslySetInnerHTML={{
                    __html: point.desc.childMarkdownRemark.html,
                  }}
                />
              ))}
          </div>
        </div>
      </section>
    </Anchor>
  )
}

export default TextModuleLong
