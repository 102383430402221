import React, { useContext } from 'react'
import { Waypoint } from 'react-waypoint'
import { MenuContext } from '../../templates/home.tsx'

interface LayoutProps {
  history: string
  callbackEnter?: any
  callbackLeave?: any
  children: any
}

const Anchor: React.SFC<AnchorProps> = ({
  history,
  callbackEnter,
  callbackLeave,
  children,
  sectionTheme,
}) => {
  const { setTheme } = useContext(MenuContext)

  // console.log('SetsectionTheme: ', SetsectionTheme)
  return (
    <Waypoint
      topOffset="90%"
      bottomOffset="90%"
      onEnter={() => {
        // SetsectionTheme =
        setTheme(sectionTheme ? sectionTheme : 'theme-dark')
        if (typeof window !== `undefined`) {
          window.history.pushState({}, '', `#${history}`)
        }
        if (callbackEnter) return callbackEnter()
      }}
      onLeave={() => {
        if (callbackLeave) return callbackLeave()
      }}
    >
      {children}
    </Waypoint>
  )
}

export default Anchor
