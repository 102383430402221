import React, { useState } from 'react'
import Img, { FluidObject } from 'gatsby-image'

import Anchor from '../Anchor'
import { ReactComponent as Next } from '../../assets/vector/icon-next.svg'
import { ReactComponent as Previous } from '../../assets/vector/icon-previous.svg'

import './style.sass'

interface CarouselProps {
  content: {
    id: string
    anchor: string
    carousel: {
      id: string
      fluid: FluidObject
    }[]
  }
}

const Carousel: React.SFC<HeroProps> = ({ content }) => {
  const [slideIdx, setSlideIdx] = useState(0)
  const length = content.carousel.length

  const handleNext = () =>
    slideIdx === length - 1 ? setSlideIdx(0) : setSlideIdx(slideIdx + 1)
  const handlePrevious = () =>
    slideIdx === 0 ? setSlideIdx(length - 1) : setSlideIdx(slideIdx - 1)

  const slideNum = slideIdx + 1 < 10 ? `0${slideIdx + 1}` : slideIdx + 1
  const totalSlides = length < 10 ? `0${length}` : length

  return (
    <Anchor history={content.anchor} sectionTheme="theme-light">
      <section className={`full-carousel`} id={content.anchor}>
        <div className="carousel">
          {Array.isArray(content.carousel) &&
            content.carousel.map((image, index) => (
              <React.Fragment key={image.id}>
                <Img
                  className={`${index === slideIdx ? 'active' : ''}`}
                  style={{ position: 'absolute' }}
                  fluid={image.fluid}
                />
                {image.description && image.title ? (
                  <div className="carousel-text">
                    <h4>{image.title}</h4>
                    <p>{image.description}</p>
                  </div>
                ) : null}
              </React.Fragment>
            ))}
        </div>
        <div className="container">
          <p>
            {slideNum}/{totalSlides}
          </p>
          <div className="arrows">
            <div className="arrow previous" onClick={handlePrevious}>
              <Previous />
            </div>
            <div className="arrow next" onClick={handleNext}>
              <Next />
            </div>
          </div>
        </div>
      </section>
    </Anchor>
  )
}

export default Carousel
