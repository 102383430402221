import React from 'react'
import Img, { FluidObject } from 'gatsby-image'
import Anchor from '../Anchor'

import './style.sass'

interface FullImageProps {
  content: {
    id: string
    anchor: string
    image: { fluid: FluidObject }
  }
}

const FullImage: React.SFC<FullImageProps> = ({ content }) => {
  return (
    <Anchor history={content.anchor}>
      <section className={`full-image`} id={content.anchor}>
        <Img fluid={content.image.fluid} />
      </section>
    </Anchor>
  )
}

export default FullImage
