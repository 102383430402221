import React, { useState } from 'react'
// import { graphql } from 'gatsby'
// import Helmet from 'react-helmet'
// import get from 'lodash/get'
// import Img from 'gatsby-image'

import Layout from '../layouts'
import Hero from '../components/Hero'
import Carousel from '../components/Carousel'
import ColourScheme from '../components/ColourScheme'
import FullImage from '../components/FullImage'
import Logo from '../components/Logo'
// import Menu from '../components/Menu'
import PhoneCarousel from '../components/PhoneCarousel'
import ImageLinks from '../components/ImageLinks'
import Statistics from '../components/Statistics'
import TextModuleCentered from '../components/textModule/TextModuleCentered'
import TextModuleCta from '../components/textModule/TextModuleCta'
import TextModuleLong from '../components/textModule/TextModuleLong'
import Typeface from '../components/Typeface'
// import SocialAggregator from '../components/SocialAggregator'
// import InstagramFeed from '../components/InstagramFeed'

export const MenuContext = React.createContext('theme-dark')

import './home.sass'

// Get all sections as ordered by PageLayout
// Currently only expects 1 PageLayout
// Pass all content from linked section into component
function getSection(sections) {
  if (!Array.isArray(sections)) {
    return null
  }

  return sections.map(section => {
    switch (section.__typename) {
      case 'ContentfulHero':
        return <Hero key={section.id} content={section} />
        break
      case 'ContentfulLogo':
        return <Logo key={section.id} content={section} />
        break
      case 'ContentfulDesktopCarousel':
        return <Carousel key={section.id} content={section} />
        break
      case 'ContentfulImageLinks':
        return <ImageLinks key={section.id} content={section} />
        break
      case 'ContentfulPhoneCarousel':
        return <PhoneCarousel key={section.id} content={section} />
        break
      case 'ContentfulColourScheme':
        return <ColourScheme key={section.id} content={section} />
        break
      case 'ContentfulFullPageImage':
        return <FullImage key={section.id} content={section} />
        break
      case 'ContentfulTypeface':
        return <Typeface key={section.id} content={section} />
        break
      case 'ContentfulStatistics':
        return <Statistics key={section.id} content={section} />
        break
      case 'ContentfulTextModuleWCta':
        return <TextModuleCta key={section.id} content={section} />
        break
      case 'ContentfulTextModuleLong':
        return <TextModuleLong key={section.id} content={section} />
        break
      case 'ContentfulTextModuleCentered':
        return <TextModuleCentered key={section.id} content={section} />
        break
      default:
        return null
    }
  })
}

const HomeTemplate: React.SFC<TypeFaceProps> = ({ pageContext }) => {
  // console.log('pageContext: ', pageContext)
  const [theme, setTheme] = useState('theme-dark')

  return (
    <Layout>
      <MenuContext.Provider value={{ theme, setTheme }}>
        {getSection(pageContext.sections)}
        {/* {pageContext.menu && (
          <Menu key={pageContext.menu.id} content={pageContext.menu} />
        )} */}
        {/* <InstagramFeed /> */}
      </MenuContext.Provider>
    </Layout>
  )
}

export default HomeTemplate
